<template>
	<div class="container-fluid animatedParent animateOnce my-3">
		<!-- main content -->
		<div class="tab-content">
			<div class="tab-pane animated fadeInUpShort show active go">
				<div class="row my-3">
					<div class="col-md-12">
						<div class="card r-0 shadow">
							<div class="card-header">

								<div class="row">
									<div class="col-md-9">
										<!-- 搜索 start -->
										<div class="form-inline mt-3">
											
											<!-- <input v-model="query" class="form-control" placeholder="搜索" type="text"> -->
											
											<!-- <div class="input-group">
												<input v-model="start_date" id="date_timepicker_start" type="text" class="date-time-picker form-control" style="width: 105px;">
												<span class="input-group-append">
													<span class="input-group-text add-on white">
														<i class="icon-calendar"></i>
													</span>
												</span>
											</div> -->
											
											<div class="ml-2">
											
											<date-picker v-model="start_date" format="YYYY-MM-DD" value-type="format"
												input-class="form-control" :editable="false" :clearable="false">
											</date-picker>
											</div>

											<!-- <div class="input-group ml-2">
												<input v-model="end_date" id="date_timepicker_end" type="text" class="date-time-picker form-control" style="width: 105px;" >
												<span class="input-group-append">
													<span class="input-group-text add-on white">
														<i class="icon-calendar"></i>
													</span>
												</span>
											</div> -->

											<div class="ml-2">
												<date-picker v-model="end_date" format="YYYY-MM-DD" value-type="format"
													input-class="form-control" :editable="false" :clearable="false">
												</date-picker>
											</div>
											
											<div class="ml-2">	
												<span>
													<select v-model="selected_ent_id" class="form-control">
														<option value="0">所有服务商</option>
														<option v-bind:key="index" 
															:value="item.id" v-for="(item,index) in enterpriselist">
															{{item.enterprise_name}}</option>
													</select>
												</span>
											</div>
											
											<!-- <div class="ml-2">
												<span>
													<select v-model="expense_type" class="form-control  ml-2">
														<option value="">所有费控类型</option>
														<option v-bind:key="index" 
															:value="item.value" v-for="(item,index) in expensetypelist">
															{{item.name}}</option>
													</select>
												</span>
											</div> -->

											<button @click="search" type="button" class="btn btn-secondary  ml-2"><i
													class="icon-search"></i>
											</button>
										</div>
										<!-- 搜索 end -->
									</div>
									<div class="col-md-3">
										<!-- <div class="float-right m-3">
											<button @click="back" type="button" class="btn btn-outline-secondary">
												<i class="icon icon-chevron-left" />
												返回</button>
										</div> -->
									</div>
								</div>
							</div>

							<div class="table-responsive">
								<div v-if="weekbilllist.length > 0">
									<table class="table table-striped table-hover r-0  mb-0">
										<thead>
											<tr class="no-b">
												<!-- <th style="width: 60px">
													序号
												</th> -->
												<th>
													<div class="d-none d-lg-block">企业名称</div>
												</th>
												<template v-for="(item,index) in datelist">
												<th v-bind:key="index">
													<div class="d-none d-lg-block">{{item}}</div>
													<div class="d-none d-lg-block">{{cnweeklist[index]}}</div>
												</th>
												</template>
											</tr>
										</thead>
										
										<tbody>
											<tr class="no-b font-weight-bold">
												<!-- <th></th> -->
												<th>P汇总</th>
												<template v-for="(subitem,subindex) in totalA.week_bill_list">
												<th v-bind:key="subindex" >
													<div class="d-none d-lg-block">
														{{subitem.count}}/￥{{subitem.amount.toFixed(2)}}
													</div>
												</th>
												</template>	
												
											</tr>
											
											<tr v-bind:key="index" v-for="(item,index) in weekbilllist"
												:class="{'blue lighten-5':selectedIndex == index}">
												<!-- <td>
													{{index+1}}
												</td> -->
												<td>
												<div class="d-none d-lg-block">
													<template v-if="!item.dept_id">{{item.ent_name}} 汇总</template>
													<template v-if="item.dept_id">
														&nbsp;&nbsp;&nbsp;&nbsp;
													{{item.dept_name}}
													</template>
												</div>
												</td>
												<template v-for="(subitem,subindex) in item.week_bill_list">
												<td v-bind:key="subindex" >
													<div class="d-none d-lg-block">
														{{subitem.count}}/￥{{subitem.amount.toFixed(2)}}
													</div>
												</td>
												</template>	
												
											</tr>
							
							
										</tbody>
										<!-- <tfoot>
											<tr class="no-b font-weight-bold">
												<th></th>
												<th>合计</th>
												<template v-for="(subitem,subindex) in totalA.week_bill_list">
												<th v-bind:key="subindex" >
													<div class="d-none d-lg-block">
														{{subitem.count}}/￥{{subitem.amount.toFixed(2)}}
													</div>
												</th>
												</template>	
												
											</tr>
										</tfoot> -->
									</table>
								</div>
								<div v-if="weekbilllist.length == 0">
									<div class="card-body text-lg-center m-5 height-300">
										<h5>没有账单记录</h5>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- <div class="row" v-if="weekbilllist.length > 0">
					<div class="col-sm-12 col-md-5">
						<div class="dataTables_info" id="example2_info" role="status" aria-live="polite">
							显示 {{show_from}} ~ {{show_to}} 条 / 共 {{count}} 条
						</div>
					</div>
					<div class="col-sm-12 col-md-7">

						<v-pagination :total="total" :current-page='current' @pagechange="pagechange"></v-pagination>

					</div>
				</div> -->

			</div>

		</div>
		<!-- //main content -->

	</div>
</template>

<script>
// import VPagination from '../components/my-page.vue'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-cn'
	
export default {
    data: function() {
        return {
			title: '企业',
			
			token: '',
			
			// setting: {},
			// outletlist: [],
			
			ent_id: 0,
			dept_id: 0,
			
			ent_name: '',
			dept_name: '',
			user_role: '',
			user_name: '',
			
			weekbilllist: [],
			query: '',
			
			department: {},
			selectedIndex: -1,
			
			selectedId: 0,
			
			disabled: false,
			
			quota: {},
			// expensetypelist: [
			// 	{name: '选择费用类型', value: ''},
			// 	{name: '弹性福利', value: 'DEFAULT'},
			// 	{name: '餐食外卖', value: 'MEAL'},
			// 	{name: '交通出行', value: 'CAR'},
			// 	{name: '工会福利', value: 'COMMON'},
			// ],
			categorylist: this.GLOBAL.categorylist,
			
			////
			enterpriselist: [],
			// dept_id: '',
			entmap: {}, //key为 dept id, value为 dept
			
			expenseTypeMap: {
				'DEFAULT': '弹性福利',
				'MEAL':'餐食外卖',
				'CAR':'交通出行',
				'COMMON': '工会福利',
			},
			
			selectedWeekBillList: [],
			selected_ent_id: 0,
			
			////
			valid_code: '',
			show: true,
			seconds: '',
			timer: null,
			
			////
			current_page: 1,
			page_size: 50,
			count: 0,
			total_page: 0,
			show_from: 0,
			show_to: 0,
			
			total: 0, // 记录总条数
			display: 10, // 每页显示条数
			current: 1, // 当前的页数
			
			////
			ent_quota: {
				total_available: 0,
			},
			
			////
			start_date: '',
			end_date: '',
			
			expensetypelist: [
				// {name: '餐饮', value: 'MEAL'},
				// {name: '通用', value: 'DEFAULT'},
				// {name: '公交地铁', value: 'METRO'},
				// {name: '用车', value: 'CAR'},
				// {name: '话费充值', value: 'PREPAYRECHARGE'},
				// {name: '酒店', value: 'HOTEL'},
				// {name: '火车票', value: 'TRAIN'},
				// {name: '机票', value: 'PLANE'},
					{name: '弹性福利', value: 'DEFAULT'},
					{name: '餐食外卖', value: 'MEAL'},
					{name: '交通出行', value: 'CAR'},
					{name: '工会福利', value: 'COMMON'},
			],
			expense_type: '',
			
			////
			datelist: [],
			cnweeklist: [],
			dateRange: {
				start: null,
				end: null
			},
			totalA: {},
        }
    },
    props: {
      
    },
	
	components: {
		// VPagination,
		DatePicker
	},
	
	created: function() {
		console.log('--- created --- ')
		let self = this;
		
		//1.检查登录
		let token = localStorage.getItem('TOKEN');
		// if (!token) {
		// 	return;
		// }
		self.token = token;
		
		//2.取得店铺信息
		let lsLoginData = JSON.parse(localStorage.getItem('LOGINDATA'));
		console.log(lsLoginData.dept_name)
		self.ent_id = lsLoginData.ent_id;
		// self.dept_id = lsLoginData.dept_id;
		
		// self.ent_name = lsLoginData.ent_name;
		// self.dept_name = lsLoginData.dept_name;
		
		// self.user_name = lsLoginData.user_account;
		
		// self.setting = JSON.parse(localStorage.getItem('SETTING'));
		
		// self.outletlist = JSON.parse(localStorage.getItem('OUTLETLIST'));
		// console.log('--- outlet list ->', self.outletlist)
	},
	mounted: function() {
		console.log('--- mounted --- ')
		let self = this;
		
		//1.检查登录
		// let token = localStorage.getItem('TOKEN');
		// if (!self.token) {
		// 	console.log('--- mounted jump  --- ')
		// 	location.href = 'index.html';
		// 	return;
		// }
		
		self.init(() => {
			self.initData();
		})
	},
    methods:{
		init(callback) {
			let self = this;
		
			//
			this.axios.get(this.GLOBAL.baseURI + 'getEnterpriseList', {
					params: {
						token: self.token,
						// dept_id: self.dept_id,
						// query: self.query,
						// current_page: self.current_page,
						// page_size: self.page_size,
					}
				})
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function(data) {
					// always executed
					console.log(data.data);
					if (data.code == 200) {
						self.enterpriselist = data.data.list;
						
						for (let i = 0; i < self.enterpriselist.length; i++) {
							let ent = self.enterpriselist[i];
							self.entmap[ent.id] = ent;
						}
						console.log('--- ent map ->', self.entmap);
						
						// self.current_date = data.data.current_date;
						
						if (typeof callback == 'function') {
						    callback()
						}
					} 
					else if (data.code == 101) {
						//登录超时
						// location.href = "index.html";
						self.logoff();
					} 
					else {
						alert(data.message)
					}
				});
			//END
		
		},
		
    	initData() {
    		let self = this;
    	
    		self.loader = self.$loading.show();
    		
    		this.axios.get(this.GLOBAL.baseURI + 'getEntWeekBillList', {
    				params: {
    					token: self.token,
    					ent_id: self.selected_ent_id, 
    		// 			expense_type: self.expense_type,
    					start_date: self.start_date,
    					end_date: self.end_date,
    					// query: self.query,
    					// current_page: self.current_page,
    					// page_size: self.page_size,
    				}
    			})
    			.then(function(response) {
    				console.log(response);
    				return response.data;
    			})
    			.catch(function(error) {
    				console.log(error);
    			})
    			.then(function(data) {
    				// always executed
    				console.log(data.data);
    				self.loader.hide();
    				
    				if (data.code == 200) {
    					self.weekbilllist = data.data.list;
    					self.datelist = data.data.week_list;
						self.cnweeklist = data.data.cn_week_list;
    					console.error('--- self.weekbilllist ->', self.weekbilllist)
    					
    					self.selectedWeekBillList = data.data.list;
    					
    		// 			self.current_page = data.data.page_num;
    		// 			self.page_size = data.data.page_size;
    		// 			self.count = data.data.total;
    		// 			self.total_page = Math.ceil(self.count / self.page_size);
    		// 			console.log('--- self.total_page ->', self.total_page)
    					
    					self.start_date = data.data.start_date;
    					self.end_date = data.data.end_date;
    					
    		// 			self.show_from = self.page_size * (self.current_page - 1) + 1;
    		// 			self.show_to = self.page_size * self.current_page <= self.count ? self.page_size * self
    		// 				.current_page : self.count;
    					
    		// 			self.total = self.count; // 记录总条数
    		// 			self.display = self.page_size; // 每页显示条数
    		// 			self.current = self.current_page; // 当前的页数
    		
    					self.totalA = data.data.total;
    				} 
    				else if (data.code == 403) {
    					 // Vue.toasted.error( '没有权限', {
    					 // 	position: 'top-center',
    					 // 	duration: '2000',
    					 // })
    					 
    					 self.$router.push({ name: '403'});
    					 
    					 return;
    				}
    				else if (data.code == 101) {
    					//登录超时
    					// location.href = "index.html";
    					self.logoff();
    				} 
    				else {
    					alert(data.message)
    				}
    			});
    		//END
    	
    	},
    	
    	search() {
    		let self = this;
			console.log('---- self.selectedWeekBillList->',self.selectedWeekBillList)
			
			if(self.selected_ent_id==0){
				self.weekbilllist = self.selectedWeekBillList;
			}
			else{
				self.weekbilllist = self.selectedWeekBillList.filter(item => item.ent_id==self.selected_ent_id);
			}
    		
    	},
		
		back(){
			var self = this;
			this.$router.push({ name: 'entquotas', params: { ent_id: self.ent_id }})//【FANDY】 视为 POST 提交
		},
    	
		
    	onOutletChangeListener(e) {
    		let self = this;
    		console.log('--- outlet ID->', e)
    		self.outlet_id = e;
    	
    		self.initData();
    	},
		
		pagechange: function(currentPage) {
			console.log(currentPage);
			// ajax请求, 向后台发送 currentPage, 来获取对应的数据
			this.current_page = currentPage;
			this.initData();
		},
    }
}
</script>

<style>
	.mx-icon-calendar,
	.mx-icon-clear {
		right: 28px !important;
	}
	.text-ellipsis{
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
</style>
